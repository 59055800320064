<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        员工里程表
      </div>
      <div class="search">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="筛选条件">
            <el-select size="small" v-model="formInline.typeId" clearable placeholder="选择筛选条件">
              <el-option
                v-for="item in filters"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择人员" v-show="formInline.typeId==1">
            <el-select size="small" v-model="formInline.staffId" clearable placeholder="选择人员">
              <el-option
                v-for="item in yuangong"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择日期" v-show="formInline.typeId==2">
            <el-date-picker
              v-model="formInline.date"
              style="width: 215px"
              value-format="timestamp"
              type="date"
              size="small"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="staffName" label="员工姓名"></el-table-column>
          <el-table-column prop="departmentName" label="所属部门"></el-table-column>
          <el-table-column prop="mileageCount" label="里程段数"></el-table-column>
          <el-table-column prop="totalDuration" label="累计时长">
            <template slot-scope="scope">
              {{ dateSecond(scope.row.totalDuration) }}
            </template>
          </el-table-column>
          <el-table-column prop="totalDistance" label="总里程（公里）">
            <template slot-scope="scope">
              {{ parseFloat((scope.row.totalDistance / 1000).toFixed(2)) }}
            </template>
          </el-table-column>
          <el-table-column prop="taskDate" label="日期">
            <template slot-scope="scope">
              {{ scope.row.taskDate ? dayjs(scope.row.taskDate).format('YYYY-MM-DD'):'-' }}
            </template>
          </el-table-column>
        </el-table>
        <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs'

export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      formInline: {},
      tableData: [],
      filters: [
        {
            id: 1,           
            name: '按人员'
        },
        {
            id: 2,
            name: '按日期'
        }
      ],
      yuangong: [],
      pickerOptions: {
        disabledDate: time => {
          let d = dayjs(time).format("YYYYMMDD").valueOf();
          let n = dayjs().format("YYYYMMDD").valueOf();
          return d < n;
        }
      },
    };
  },
  methods: {
    dayjs,
    details(row) {
      // this.$router.push({ name: 'deviceScrapDetail', params: {row: row}})
    },
    onSubmit() {
      this.page = 1
      sessionStorage.setItem('currentPage', 1)
      this.loadListData()
    },
    dateSecond(val) {
      if (val) {
        let s = val % 60
        let _s = val - s

        let m = (_s / 60) % 60

        let h = (_s / 60 - m) / 60

        return `${h>9 ? h:'0'+h}:${m>9 ? m:'0'+m}:${s>9 ? s:'0'+s}`
      } else {
        return '00:00:00'
      }
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadListData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadListData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
    loadListData() {
      this.loading = true
      this.$ajax.post('staffMileage', {
        date: this.formInline.date?this.formInline.date:null,
        staffId: this.formInline.staffId?this.formInline.staffId:null,
        pageNum: this.page,
        pageSize: this.size
      }).then(res => {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    load_yuangong() {
      this.$ajax.get("staffDueryAll").then((res) => {
        this.yuangong = res.data;
      });
    }
  },
  mounted() {
    this.getLastPage()
    this.getSize()
    this.load_yuangong();
    this.loadListData()
  },
  destroyed() {
    sessionStorage.removeItem('currentPage')
    sessionStorage.removeItem('currentSize')
  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.btn {
  float: right;
}
.search {
  padding: 0 10px;
}
</style>